import React, { useState, useEffect, useRef } from 'react';
import {
    Form,
    Input,
    Button,
    Col,
    Row,
    Card,
    Divider,
    Typography,
    Image,
    List,
    message,
    Result,
    notification,
    Spin,
} from 'antd';
import {
    LoadingOutlined,
    SearchOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import useOrder from '../../../hooks/useOrder';
import Food from '../../../assets/img/jpg/seafood.jpg';
import { formatMoney, userLogOut } from '../../../utils/general';
import BasketForm from '../../../components/Client/BasketForm';
import DescriptionItem from '../../../components/Client/DescriptionItem';
import Modal from '../../../components/General/Modal';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompanyIdApi } from '../../../api/company';
import { getMenuItemsApi } from '../../../api/menu';

import './SearchForm.scss';

const { Title, Text } = Typography;

const SearchForm = ({ infoCompany, scheduleCompany }) => {
    const isMounted = useRef(true);
    const { orderGeneral } = useOrder();
    const { orderLinesList, Subtotal } = orderGeneral;
    const dayNumber = moment().day() === 0 ? 7 : moment().day();
    const token = getAccessTokenApi();
    const [loading, setLoading] = useState(false);
    const [counterItem, setCounterItem] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [titleModal, setTitleModal] = useState('');
    const [searchWord, setSearchWord] = useState('');
    const [items, setItems] = useState([]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        setCounterItem(orderLinesList.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderGeneral]);

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    // const onSearch = (value) =>{
    //     setLoading(true);
    //     console.log(value);
    //     setSearchWord(value);
    //     const token = getAccessTokenApi();
    //     const idCompany = getCompanyIdApi();

    //     getMenuItemsApi(token, idCompany, value).then( response => {
    //         setLoading(false);
    //         setItems(response);
    //         console.log(response);
    //     })

    // }

    const onValuesChange = ({ itemName }) => {
        if (itemName.length >= 3) {
            setLoading(true);
            setSearchWord(itemName);
            const idCompany = getCompanyIdApi();

            getMenuItemsApi(token, idCompany, itemName).then((response) => {
                if (isMounted.current) {
                    setLoading(false);
                    setItems(response);
                }
            });
        }
    };

    const validSchedule = () => {
        let now = moment().format('YYYY-MM-DD');
        const dayFound = scheduleCompany.Schedule.find(
            (element) => element.dayOfWeek === dayNumber
        );

        // let nextDay = 0;
        let response = {
            isClose: true,
            isBetween: false,
            nextDay: 0,
            message: '',
        };

        if (dayNumber >= 1 && dayNumber <= 6) {
            response.nextDay = dayNumber + 1;
        } else {
            response.nextDay = 1;
        }

        if (dayFound !== undefined) {
            //is open
            let timeDay = {
                timeStart: moment(`${now} ${dayFound.timeStart}`),
                timeEnd: moment(`${now} ${dayFound.timeEnd}`),
            };

            if (moment(timeDay.timeEnd).isBefore(timeDay.timeStart)) {
                //LA HORA FINAL ES ANTES QUE LA FINAL
                //SUMARLE UN DIA
                timeDay.timeEnd = timeDay.timeEnd.add(1, 'd');
            }

            let isBetween = moment().isBetween(
                timeDay.timeStart,
                timeDay.timeEnd
            ); // true

            response.isClose = false;
            response.isBetween = isBetween;

            if (!isBetween) {
                const isBeforeStart = moment().isBefore(timeDay.timeStart);

                if (isBeforeStart) {
                    response.message = `Apertura: ${timeDay.timeStart.format(
                        'h:mm A'
                    )} a ${timeDay.timeEnd.format('h:mm A')} `;
                } else {
                    response = NextOpening(response, now);
                }
            }
        } else {
            //is not open this day
            response = NextOpening(response, now);
        }

        return response;
    };

    const NextOpening = (response, now) => {
        let findNextDay = false;

        while (!findNextDay) {
            const dayNextFound = scheduleCompany.Schedule.find(
                (element) => element.dayOfWeek === response.nextDay
            );

            if (dayNextFound === undefined) {
                if (response.nextDay >= 1 && response.nextDay <= 6) {
                    response.nextDay = response.nextDay + 1;
                } else {
                    response.nextDay = 1;
                }
            } else {
                response.nextDayTimeStart = moment(
                    `${now} ${dayNextFound.timeStart}`
                );
                response.nextDayTimeEnd = moment(
                    `${now} ${dayNextFound.timeStart}`
                );

                let dayString = '';
                switch (dayNextFound.dayOfWeek) {
                    case 1:
                        dayString = 'Lunes';
                        break;
                    case 2:
                        dayString = 'Martes';
                        break;
                    case 3:
                        dayString = 'Miércoles';
                        break;
                    case 4:
                        dayString = 'Jueves';
                        break;
                    case 5:
                        dayString = 'Viernes';
                        break;
                    case 6:
                        dayString = 'Sábado';
                        break;
                    case 7:
                        dayString = 'Domingo';
                        break;

                    default:
                        break;
                }
                response.message = `Próxima apertura: ${dayString} de ${moment(
                    `${now} ${dayNextFound.timeStart}`
                ).format('h:mm A')} a ${moment(
                    `${now} ${dayNextFound.timeEnd}`
                ).format('h:mm A')} `;
                findNextDay = true;
            }
        }

        return response;
    };

    const showModal = (item, title) => {
        if (infoCompany !== undefined) {
            //CHECK IF EXIST ORDER ACTIVE AND RESTAURANT ALLOW MULTI ORDER ACTIVE
            if (!infoCompany.AllowMultipleActiveOrders) {
                if (infoCompany.ActiveOrder !== undefined) {
                    if (infoCompany.ActiveOrder) {
                        notification['info']({
                            message: 'Ya tienes una orden en proceso',
                            description:
                                'Debes finalizar una orden para poder realizar una nueva',
                        });
                        return false;
                    }
                }
            }
        } else {
            notification['error']({
                message: 'Ocurrio un error al cargar la información',
            });
            setTimeout(() => {
                window.location.href = '/login';
            }, 1500);
            return;
        }

        if (title === 'Order') {
            setContextModal(
                <BasketForm
                    setCounterItem={setCounterItem}
                    setIsModalVisible={setIsModalVisible}
                    infoCompany={infoCompany}
                />
            );
            setTitleModal('Orden');
            setIsModalVisible(true);
        } else {
            if (scheduleCompany.Schedule.length === 0) {
                let msg = `El restaurante esta temporalmente cerrado.`;
                message.warning(msg, 3);
                setIsModalVisible(false);
                return false;
            }

            const validateSchedule = validSchedule();

            if (validateSchedule.isClose) {
                let msg = `Lo sentimos el establecimiento esta cerrado este día. ${validateSchedule.message}`;
                message.warning(msg, 3);
                setIsModalVisible(false);
                return false;
            }

            if (!validateSchedule.isClose && !validateSchedule.isBetween) {
                let msg = `Lo sentimos el establecimiento esta cerrado.  ${validateSchedule.message}`;
                message.warning(msg, 3);
                setIsModalVisible(false);
                return false;
            }

            setContextModal(
                <DescriptionItem
                    item={item}
                    setIsModalVisible={setIsModalVisible}
                />
            );
            setTitleModal(title);
            setIsModalVisible(true);
        }
    };

    return (
        <>
            <div
                className={
                    'menu-search' +
                    (counterItem > 0 ? ' search-active-scroll' : '')
                }
            >
                {/* <Search
                prefix={<SearchOutlined />} 
                placeholder="Buscar producto del menú" 
                allowClear 
                onSearch={onSearch}
                enterButton="Buscar"
                // suffix={loading=== true ? <LoadingOutlined /> : <Button type="text" icon={<CloseOutlined />} onClick={()=>setshowViewMenu(true)} />}
            /> */}

                <Form
                    {...layout}
                    name="searchMenu"
                    onValuesChange={onValuesChange}
                >
                    <Form.Item name="itemName">
                        <Input
                            prefix={<SearchOutlined />}
                            placeholder="Buscar producto del menú"
                            allowClear
                            suffix={
                                loading === true ? <LoadingOutlined /> : <> </>
                            }
                        />
                    </Form.Item>
                </Form>

                <Divider />

                {items.length > 0 && !loading && (
                    <List
                        itemLayout="horizontal"
                        dataSource={items}
                        locale="No se ha encontrado platillos"
                        renderItem={(item) => (
                            <CardItem item={item} showModal={showModal} />
                        )}
                    />
                )}

                {items.length === 0 && searchWord !== '' && !loading && (
                    <Result
                        status="404"
                        title="No se encontraron resultados"
                        subTitle="Intenta con un termino de búsqueda diferente."
                    />
                )}

                {loading && searchWord !== '' && <Spin size="large" />}
            </div>

            {counterItem > 0 && (
                <div className="menu-costumer-button">
                    <Button
                        type="primary"
                        block
                        danger
                        size="large"
                        onClick={() => showModal(undefined, 'Order')}
                    >
                        <Row>
                            <Col
                                span={4}
                                className="menu-costumer-button__left"
                            >
                                <div className="counter-square">
                                    {counterItem}
                                </div>
                            </Col>

                            <Col
                                span={10}
                                className="menu-costumer-button__center"
                            >
                                Ver Canasta
                            </Col>

                            <Col
                                span={10}
                                className="menu-costumer-button__right"
                            >
                                <p>Total ${formatMoney(Subtotal)}</p>
                            </Col>
                        </Row>
                    </Button>
                </div>
            )}

            <Modal
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                title={titleModal}
                footer={false}
                destroyOnClose
            >
                {contextModal}
            </Modal>
        </>
    );
};

export default SearchForm;

function CardItem({ item, showModal }) {
    const { name, available, description, price, itemImage } = item;

    return (
        <Card className="card-menu-search" style={{ marginBottom: 20 }}>
            <Row>
                <Col span={14}>
                    <Title
                        level={4}
                        style={{ marginBottom: 0 }}
                        className="card-menu-search__title"
                    >
                        {name}
                    </Title>
                    {!available && (
                        <p className="card-menu-search__noAvailable">
                            No está disponible
                        </p>
                    )}
                    <Text className="card-menu-search__description">
                        {description}
                    </Text>
                    <p> ${formatMoney(price)}</p>
                    <Button
                        danger
                        icon={
                            <PlusOutlined
                                className={available ? 'color-red ' : ''}
                            />
                        }
                        onClick={() => showModal(item, '')}
                        disabled={available ? false : true}
                    >
                        Agregar
                    </Button>
                </Col>
                <Col span={10} className="text-right">
                    {itemImage !== null && (
                        <Image
                            width={120}
                            src={
                                itemImage !== null
                                    ? `data:image/${itemImage.extension};base64, ${itemImage.imageData}`
                                    : Food
                            }
                        />
                    )}
                </Col>
            </Row>
        </Card>
    );
}
