import React, { useState } from 'react';
import {
    Form,
    Input,
    InputNumber,
    Button,
    Select,
    Checkbox,
    Tooltip,
    Spin,
    notification,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { validatePassword } from '../../../utils/formValidation';
import { signUpApi } from '../../../api/user';
import fileTerminos from '../../../assets/pdf/TERMINOS-DE-USO-DEL-PRODUCTO-Y-DEL-SITIO-WEB-CONTROL-B.pdf';
import { getMobileOperatingSystem } from '../../../utils/subscriptions';

import './RegisterForm.scss';

export default function RegisterForm(props) {
    const { setShowRegisterForm, setEmail, companyId } = props;
    const { Option } = Select;

    const [loading, setLoading] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
            lg: {
                span: 6,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 16,
            },
            lg: {
                span: 14,
            },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
            lg: {
                span: 8,
                offset: 8,
            },
        },
    };

    const [form] = Form.useForm();

    const onSend = (email) => {
        setEmail(email);
        setShowRegisterForm(false);
    };

    const onFinish = async (values) => {
        setLoading(true);

        let formData = values;

        formData['phoneNumber'] = formData['phoneNumber'].toString();
        formData['address'] = null;
        formData['address2'] = null;
        formData['city'] = null;
        formData['neighborhood'] = null;
        formData['phoneExtensionNumber'] = null;
        formData['vehicule'] = null;
        formData['registrationNumber'] = null;
        formData['companyId'] = companyId;

        const result = await signUpApi(formData);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });

            return false;
        }

        if (result.statusCode !== 200) {
            if (result.statusCode === 409) {
                notification['error']({
                    message: result.description,
                });

                setLoading(false);

                return true;
            }

            notification['error']({
                message: 'Ocurrio un problema, Intente mas tarde por favor.',
            });
            setLoading(false);

            return true;
        } else {
            //recreo correctamenta
            form.resetFields();
            notification['success']({
                message: 'Usuario creado exitosamente',
            });

            onSend(values.email);
            // setTimeout(() => {
            //     setLoading(false);
            //     window.location.href = '/login';
            // }, 2000);
        }
    };

    return (
        <Spin tip="Creando cuenta..." spinning={loading}>
            <Form
                {...formItemLayout}
                className="register-form"
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
            >
                <Form.Item
                    name="Name"
                    label="Nombre del usuario"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un nombre',
                            whitespace: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="LastName"
                    label="Apellido del usuario"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un apellido',
                            whitespace: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="E-mail"
                    hasFeedback
                    rules={[
                        {
                            type: 'email',
                            message: 'El correo electrónico no es válido',
                        },
                        {
                            required: true,
                            message: 'Por favor ingrese un correo electrónico',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Contraseña"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese una contraseña',
                        },
                        () => ({
                            validator(rule, value) {
                                if (validatePassword(value)) {
                                    return Promise.resolve();
                                } else if (!validatePassword(value)) {
                                    return Promise.reject(
                                        'La contrase\u00F1a nueva debe de tener  mínimo 6 o más caracteres, por lo menos una may\u00FAscula, un número  y un car\u00E1cter especial'
                                    );
                                }
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirmpassword"
                    label="Confirmar Contraseña"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese la contraseña anterior',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (
                                    !value ||
                                    getFieldValue('password') === value
                                ) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    'Las dos contraseñas ingresadas no son iguales'
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="UserType"
                    label="Tipo de Usuario"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor selecciona una opción',
                        },
                    ]}
                >
                    <Select placeholder="Por favor selecciona una opción">
                        <Option value={3}>Cliente</Option>
                        {/* <Option value="runner">Repartidor</Option> */}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="phoneNumber"
                    label={
                        <span>
                            Teléfono&nbsp;
                            <Tooltip title="Tú número telefónico debe contener 10 dígitos">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingresa tú número telefónico',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || value.toString().length === 10) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    'Por favor ingresa tú número telefónico a 10 dígitos'
                                );
                            },
                        }),
                    ]}
                >
                    <InputNumber
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    className="register-form__container"
                    rules={[
                        {
                            validator: (_, value) =>
                                value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                          'Debe aceptar los Términos y Condiciones'
                                      ),
                        },
                    ]}
                    {...tailFormItemLayout}
                >
                    <Checkbox>
                        He leído y aceptado los
                        {getMobileOperatingSystem() !== 'iOS' && (
                            <a
                                href={fileTerminos}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {' '}
                                Términos y Condiciones
                            </a>
                        )}
                        {getMobileOperatingSystem() === 'iOS' && (
                            <a
                                href="http://controlb.controlbsys.com/pdf/TERMINOS-DE-USO-DEL-PRODUCTO-Y-DEL-SITIO-WEB-CONTROL-B.pdf"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {' '}
                                Términos y Condiciones
                            </a>
                        )}
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    {...tailFormItemLayout}
                    className="register-form__container"
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        block={true}
                        size="large"
                    >
                        Registrar
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}
