import React from "react";
import { Typography, Row, Col } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import './Secciones.scss';

const OptionsTable = ({ section, onChange, max, options = [] }) => {
  const [contador, setContador] = React.useState(0);
  const [checked, setChecked] = React.useState([]);

  const onCheckBoxChanged = (index) => {
    let copy = [...checked];
    if (contador <= max) {
      if (!copy.includes(index)) copy.push(index);
      else copy = copy.filter((c) => c !== index);
    }
    if (max) setContador(copy.length);
    setChecked(copy);
    onChange({ section, checked: copy });
  };
  return options.map((option, index) => {
    const isDisabled =
      !option.available || (checked.length === max && !checked.includes(index));

    return (
      <div key={index}>
        <Row className="container-ingredients">
          <div className="container-productName">
            <p>{option.name}</p>
            {option.hasExtraPrice && (
              <p className="margin-price">
                $ {parseFloat(option.price).toFixed(2)}
              </p>
            )}
          </div>
          <Row className="container-price-check">
            <Row>{!option.available && (
              <p className="disable-section">No disponible</p>
            )}
              <Checkbox
                onChange={() => onCheckBoxChanged(index)}
                checked={checked.includes(index)}
                disabled={isDisabled}
                name="ingredient"
              />
            </Row>
          </Row>
        </Row>
      </div>
    );
  });
};

const Secciones = (props) => {
  const { itemSections, onChange } = props;
  const { Title } = Typography;
  const [resultado, setResultado] = React.useState({});

  const onOptionsTableChange = (obj) => {
    const { section, checked } = obj;
    let res = { ...resultado, [section]: checked };
    setResultado(res);
    onChange(res);
  };

  return (
    <>
      {itemSections.length >= 1 &&
        itemSections.map((itemSec, index) => {
          return (
            <div key={itemSec.itemSectionId}>
              {itemSec.available && (
                <>
                  <Row className="container-gray-ingredients">
                    <Col>
                      <Title level={5}>{itemSec.name}</Title>
                      {itemSec.maxOptions !== null && (
                        <p>Selecciona hasta {itemSec.maxOptions}</p>

                      )}
                      {itemSec.maxOptions === null && (
                        <p>Selecciona hasta {itemSec.sectionOptions.map(selected => selected.available === true).reduce((a, b) => a + b, 0)}</p>
                      )}
                    </Col>
                    <Col>

                      {itemSec.obligatory && <p>Requerido 1</p>}
                    </Col>
                  </Row>
                  <OptionsTable
                    section={itemSec.itemSectionId}
                    options={itemSec.sectionOptions}
                    max={itemSec.maxOptions}
                    onChange={onOptionsTableChange}
                  />
                  {itemSec.sectionOptions.length === 0 && (
                    <Row>
                      <div className="container-productName">
                        <p>No hay opciones</p>
                      </div>
                    </Row>
                  )}
                </>
              )}
            </div>
          );
        })}
    </>
  );
};

export default Secciones;
