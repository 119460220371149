import React, { useState, useEffect } from 'react';
import {
    Spin,
    Form,
    InputNumber,
    Select,
    Row,
    Col,
    Divider,
    Button,
    Card,
    Popconfirm,
    Typography,
    message,
    notification,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getCompanyApi, getCompanyIdApi } from '../../../api/company';
import { editOrder, deleteOrderDetailApi } from '../../../api/order';
import { formatMoney, userLogOut } from '../../../utils/general';
import DeliveryCostForm from '../../../components/Client/DeliveryCostForm';
import Modal from '../../../components/General/Modal';
import { useInfoCompany } from '../../../hooks/useInfoCompany';

import './EditOrderForm.scss';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const EditOrderForm = (props) => {
    const {
        setIsModalVisible,
        order,
        setReloadPendingOrders,
        setReloadOrders,
    } = props;
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const [orderForm, setOrderForm] = useState([]);
    const [orderState, setOrderState] = useState(order);
    const [reloadOrder, setReloadOrder] = useState(false);
    const [typeDelivery, setTypeDelivery] = useState(order.orderType);
    const [showChangeOf, setShowChangeOf] = useState(
        order.payType === 1 ? true : false
    );
    const [form] = Form.useForm();
    let [subtotal, setSubtotal] = useState(0);
    const { infoCompany, setInfoCompany } = useInfoCompany(null);
    const { Option } = Select;
    const { Text } = Typography;
    const [loadingForm, setLoadingForm] = useState(false);
    const [costDelivery, setCostdelivery] = useState(order.delivery);
    const [address, setAddress] = useState(order.clientAddress);
    const [distance, setDistance] = useState(order.distance);
    const [isModalVisibleCost, setIsModalVisibleCost] = useState(false);
    const [tipOrder, setTipOrder] = useState(order.tip);

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        console.log(order.details, 'ORDER');

        setOrderForm([
            {
                name: ['orderType'],
                value: order.orderType,
            },
            {
                name: ['clientAddress'],
                value: order.clientAddress,
            },
            // {
            //     name: ['table'],
            //     value: order.tableNumber=== 0 ? "": order.tableNumber,
            // },
            {
                name: ['payType'],
                value: order.payType,
            },
            // {
            //     name: ['deliveryCost'],
            //     value: costDelivery,
            // },
            // {
            //     name: ['deliveryCost'],
            //     value: order.delivery,
            // },
            {
                name: ['changeOf'],
                value:
                    order.changeOf === 0
                        ? order.total.toFixed(2)
                        : order.changeOf,
                // value: order.changeOf === 0 ?  order.orderType ===2 ? formatMoney(order.subTotal+order.tip+order.delivery) : formatMoney(order.subTotal+order.tip)  : formatMoney(order.changeOf),
            },
        ]);

        getCompanyApi(token, companyId).then((response) => {
            if (response.error) {
                return false;
            }

            setInfoCompany({
                Location: response.address,
                DeliveryCostKM: response.deliveryCostKM,
                DeliveryExtraKmCost: response.deliveryExtraKmCost,
                DeliverryMaxDistance: response.deliverryMaxDistance,
                ServiceMaxDistance: response.serviceMaxDistance,
                DeliveryCost: response.deliveryCost,
                Latitude: response.latitude,
                Longitude: response.longitude,
                OrderMinimumPurchase: response.orderMinimumPurchase,
            });
        });

        return () => {
            form.resetFields();
            setOrderForm([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, costDelivery]);

    useEffect(() => {
        let suma = 0;

        orderState.details.forEach((element) => {
            let extraPrice = 0;
            element.selectedOptions.forEach(
                (selected) => (extraPrice += selected.price)
            );
            if (element.orderDetailUom !== null) {
                suma =
                    suma +
                    (extraPrice +
                        element.unitPrice * element.orderDetailUom.units) *
                        element.quantity;
            } else {
                suma =
                    suma + (extraPrice + element.unitPrice) * element.quantity;
            }
            // console.log(suma);
            // suma = suma + element.unitPrice * element.quantity;
        });
        setSubtotal(suma);
        setTipOrder((suma * order.tipPercent) / 100);
        setReloadOrder(false);

        return () => {
            setSubtotal(0);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadOrder]);

    // const companyId = getCompanyIdApi();

    function incrementItem(dish) {
        const found = order.details.findIndex(
            (item) => item.detailId === dish.detailId
        );
        orderState.details[found].quantity = dish.quantity + 1;
        setOrderState(orderState);
        setReloadOrder(true);
    }

    function decrementItem(dish) {
        const found = order.details.findIndex(
            (item) => item.detailId === dish.detailId
        );
        orderState.details[found].quantity = dish.quantity - 1;
        setOrderState(orderState);
        setReloadOrder(true);
    }

    function confirm(itemId) {
        setLoadingForm(true);

        if (order.details.length > 1) {
            const foundPosition = order.details.findIndex(
                (item) => item.detailId === itemId
            );
            const found = order.details.find(
                (item) => item.detailId === itemId
            );
            deleteDish(foundPosition, found);
        } else {
            message.error(
                'No se puede quedar sin artículos, en caso contrario se necesitaría cancelar orden'
            );
            setLoadingForm(false);
        }
    }

    const deleteDish = (itemPosition, item) => {
        let data = {
            CompanyId: orderState.companyId,
            DetailId: item.detailId,
            OrderId: orderState.orderId,
        };

        deleteOrderDetailApi(token, data)
            .then((res) => {
                if (res.message) {
                    message.error(
                        `Ocurrio un porblema, ${item.itemName} no se pudo eliminar`
                    );
                    setLoadingForm(false);
                    return false;
                } else {
                    orderState.details.splice(itemPosition, 1);
                    setOrderState(orderState);
                    setReloadOrder(true);
                    message.success('Eliminado exitosamente');
                    setLoadingForm(false);
                    return true;
                }
            })
            .catch((err) => {
                return false;
            });
    };

    function cancel(e) {}

    const onFinish = async (values) => {
        if (infoCompany.OrderMinimumPurchase) {
            if (subtotal < infoCompany.OrderMinimumPurchase) {
                notification['error']({
                    message: `Pedido mínimo de $ ${formatMoney(
                        infoCompany.OrderMinimumPurchase
                    )} `,
                });

                return;
            }
        }
        setLoadingForm(true);
        let totalOrder = 0;
        if (typeDelivery === 2) {
            if (order.delivery === 0) {
                totalOrder = subtotal + costDelivery + tipOrder;
            } else {
                totalOrder = subtotal + costDelivery + tipOrder;

                // totalOrder = subtotal + order.delivery + tipOrder;
            }
        } else {
            totalOrder = subtotal + tipOrder;
        }

        let totalOrderFinal = totalOrder;
        // console.log(totalOrderFinal, "totalOrderFinal");
        // console.log(values, "values");
        if (values.payType === 1) {
            if (parseFloat(values.changeOf) !== 0) {
                if (parseFloat(values.changeOf) < totalOrderFinal) {
                    setLoadingForm(false);

                    notification['error']({
                        message:
                            'El cambio debe ser IGUAL o MAYOR que el total.',
                    });

                    return false;
                }
            } else {
                setLoadingForm(false);

                notification['error']({
                    message: 'El cambio debe ser IGUAL o MAYOR que el total.',
                });

                return false;
            }
        }

        orderState.orderType = typeDelivery;
        orderState.payType = values.payType;

        if (values.deliveryCost) {
            orderState.delivery = values.deliveryCost;
        } else {
            orderState.delivery = costDelivery;
        }
        orderState['orderLinesList'] = orderState.details;

        //validar que el admin puede modificar el costo de envio
        // orderState.ignoreDeliveryCost = false;

        //paypal credit card
        if (orderState.payType === 2) {
            orderState.changeOf = 0;
        } else {
            orderState.changeOf = parseFloat(values.changeOf);
        }

        if (typeDelivery === 2) {
            orderState.clientAddress = address;
            // orderState.tableNumber = 0;
            if (orderState.distance !== distance) {
                orderState.distance = distance;
            }
        }

        // if(values.orderType === 3){
        //     orderState.tableNumber = parseInt(values.table);
        //     orderState.clientAddress = "-";

        // }

        orderState.estimatedDeliveryDate = 0;

        // console.log('Orden Modificada');
        console.log(orderState, 'ORDEN A ENVIAR');
        const result = await editOrder(token, orderState);
        // console.log(result, "result");

        if (result === undefined) {
            setLoadingForm(false);

            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });

            return false;
        }

        if (result.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });

            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1200);

            return false;
        }

        if (result.statusCode === 200) {
            notification['success']({
                message: 'Orden modificada exitosamente',
            });

            if (result.result.status === 1) {
                setReloadOrders(true);
            } else {
                setReloadPendingOrders(true);
            }

            setLoadingForm(false);
            setIsModalVisible(false);
        } else {
            notification['error']({
                message:
                    'Ocurrio un problema al modificar una orden, Intentelo otra vez',
            });

            setLoadingForm(false);
        }
    };

    const Cancel = () => {
        setIsModalVisible(false);
    };

    return (
        <Spin tip="Guardando..." spinning={loadingForm} size="large">
            <Form
                {...layout}
                className="edit-order"
                form={form}
                onFinish={onFinish}
                fields={orderForm}
                onFieldsChange={(changedFields, allFields) => {
                    if (changedFields[0].name[0] === 'payType') {
                        setShowChangeOf(
                            changedFields[0].value === 1 ? true : false
                        );
                    }

                    if (changedFields[0].name[0] === 'clientAddress') {
                        setAddress(changedFields[0].value);
                    }
                    // if(changedFields)
                    setOrderForm(allFields);
                }}
            >
                <Form.Item
                    name="orderType"
                    label="Tipo de pedido"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor seleccione un tipo de pedido',
                        },
                    ]}
                >
                    <Select
                        placeholder="Seleccione un tipo de pedido"
                        onChange={(e) => setTypeDelivery(e)}
                    >
                        <Option value={1}>Para llevar</Option>
                        <Option value={2}>A domicilio</Option>
                        {/* <Option value={3}>Para la mesa</Option> */}
                    </Select>
                </Form.Item>

                {typeDelivery === 2 && (
                    <>
                        <p>Dirección: {address}</p>
                        <Button
                            type="ghost"
                            block
                            onClick={() => setIsModalVisibleCost(true)}
                        >
                            Editar Dirección
                        </Button>
                    </>
                )}

                {/* {typeDelivery === 3 && <Form.Item
                label="Mesa"
                name="table"
                rules={[
                {
                    required: true,
                    message: 'Por favor ingrese una mesa',
                },
                ]}
            >
                <InputNumber
                    style={{
                        width: '100%',
                    }}
                    placeholder="Mesa" 
                />
            </Form.Item>} */}

                <Form.Item
                    className="mt-20"
                    name="payType"
                    label="Tipo de pago"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor seleccione un tipo de pedido',
                        },
                    ]}
                >
                    <Select placeholder="Seleccione un tipo de pedido">
                        <Option value={1}>Efectivo</Option>
                        <Option value={2} disabled>
                            Tárjeta de crédito/débito
                        </Option>
                        {/* <Option value={2}>Terminal a domicilio</Option> */}
                    </Select>
                </Form.Item>

                {showChangeOf && (
                    <Form.Item
                        label="Pagará con"
                        name="changeOf"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingrese el precio',
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            style={{
                                width: '100%',
                            }}
                            formatter={(value) =>
                                `$ ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                )
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            placeholder="Precio"
                        />
                    </Form.Item>
                )}
                <div
                    className={
                        orderState.details.length > 3
                            ? 'basket-form__container-groupsItem'
                            : ''
                    }
                >
                    {orderState.details.map((ele) => {
                        return (
                            <Card className="card-order" key={ele.detailId}>
                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={24}>
                                                <h3>{ele.itemName}</h3>
                                                {ele.orderDetailUom !==
                                                    null && (
                                                    <p className="margin-left">
                                                        (
                                                        {
                                                            ele.orderDetailUom
                                                                .units
                                                        }{' '}
                                                        {
                                                            ele.orderDetailUom
                                                                .unitTypeName
                                                        }
                                                        )
                                                    </p>
                                                )}
                                            </Col>
                                            <Col span={24}>
                                                <p>
                                                    {ele.orderDetailUom !== null
                                                        ? formatMoney(
                                                              ele.unitPrice *
                                                                  ele.quantity *
                                                                  ele
                                                                      .orderDetailUom
                                                                      .units
                                                          )
                                                        : formatMoney(
                                                              ele.unitPrice *
                                                                  ele.quantity
                                                          )}
                                                    {/* {formatMoney(
                                                        ele.linePrice *
                                                            ele.quantity
                                                    )} */}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col span={12}>
                                        <Row className="counter">
                                            <Col span={8}>
                                                <Button
                                                    type="text"
                                                    size="large"
                                                    disabled={
                                                        ele.quantity === 1
                                                    }
                                                    onClick={() =>
                                                        decrementItem(ele)
                                                    }
                                                >
                                                    -
                                                </Button>
                                            </Col>
                                            <Col
                                                span={8}
                                                className="counter__number"
                                            >
                                                {ele.quantity}
                                            </Col>
                                            <Col span={8}>
                                                <Button
                                                    type="text"
                                                    size="large"
                                                    onClick={() =>
                                                        incrementItem(ele)
                                                    }
                                                >
                                                    +
                                                </Button>
                                            </Col>
                                            <Col
                                                span={24}
                                                className="text-center"
                                            >
                                                <Popconfirm
                                                    title={`¿Estás seguro de eliminar el platillo: ${ele.itemName}?`}
                                                    onConfirm={() =>
                                                        confirm(ele.detailId)
                                                    }
                                                    onCancel={cancel}
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        icon={
                                                            <DeleteOutlined />
                                                        }
                                                    />
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <div>
                                        {ele.selectedOptions.map((option) => (
                                            <Row key={option.id}>
                                                <Col span={24}>
                                                    <Text className="section-items">
                                                        <Text
                                                            strong
                                                        >{`${option.sectionName}: `}</Text>
                                                        <Text>{`${option.name}`}</Text>

                                                        {option.price && (
                                                            <Text className="gray margin-left">
                                                                $
                                                                {formatMoney(
                                                                    option.price
                                                                )}
                                                            </Text>
                                                        )}
                                                        {option.price &&
                                                            ele.quantity >
                                                                1 && (
                                                                <Text className="gray margin-left">
                                                                    ($
                                                                    {formatMoney(
                                                                        option.price *
                                                                            ele.quantity
                                                                    )}
                                                                    )
                                                                </Text>
                                                            )}
                                                    </Text>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                </Row>
                            </Card>
                        );
                    })}
                </div>

                {typeDelivery === 2 && orderState.ignoreDeliveryCost && (
                    // <Form.Item
                    //     label="Costo de envío"
                    //     name="deliveryCost"
                    //     rules={[
                    //         {
                    //             required: true,
                    //             message: 'Por favor ingrese el costo de envio',
                    //         },
                    //     ]}
                    // >
                    //     <InputNumber
                    //         min={0}
                    //         style={{
                    //             width: '100%',
                    //         }}
                    //         formatter={(value) =>
                    //             `$ ${value}`.replace(
                    //                 /\B(?=(\d{3})+(?!\d))/g,
                    //                 ','
                    //             )
                    //         }
                    //         parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    //         placeholder="Costo de envío"
                    //         // onChange={(value) => setCostdelivery(value)}
                    //     />
                    // </Form.Item>

                    <Row gutter={[16, 10]}>
                        <Col xs={24} sm={8}>
                            <Text type="danger">*</Text>
                            <Text style={{ marginLeft: 5 }}>
                                Costo de envío:
                            </Text>
                        </Col>
                        <Col xs={24} sm={16}>
                            <InputNumber
                                min={0}
                                style={{
                                    width: '100%',
                                }}
                                value={costDelivery}
                                formatter={(value) =>
                                    `$ ${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '')
                                }
                                placeholder="Costo de envío"
                                onChange={(value) => setCostdelivery(value)}
                            />
                        </Col>
                    </Row>
                )}

                {typeDelivery === 2 && !orderState.ignoreDeliveryCost && (
                    <h4>Envío: ${formatMoney(costDelivery)}</h4>
                )}

                <h4>Propina: ${formatMoney(tipOrder)}</h4>

                {typeDelivery === 2 && (
                    <h4>
                        Cantidad total a pagar: $
                        {formatMoney(subtotal + costDelivery + tipOrder)}
                    </h4>
                )}
                {typeDelivery !== 2 && (
                    <h4>
                        Cantidad total a pagar: $
                        {formatMoney(subtotal + tipOrder)}
                    </h4>
                )}

                <Divider></Divider>

                <Row>
                    <Col span={12}>
                        <Button type="ghost" onClick={Cancel}>
                            Cancelar
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Form.Item {...tailLayout}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={infoCompany == null ? true : false}
                            >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Modal
                isVisible={isModalVisibleCost}
                setIsVisible={setIsModalVisibleCost}
                title={'Costo de Envío'}
                footer={false}
                destroyOnClose={true}
            >
                <DeliveryCostForm
                    // address={address}
                    infoCompany={infoCompany}
                    history={'admin'}
                    action={'edit'}
                    setCostdelivery={setCostdelivery}
                    setAddress={setAddress}
                    setDistance={setDistance}
                    setIsModalVisibleCost={setIsModalVisibleCost}
                    destroyOnClose={true}
                    setOrderState={setOrderState}
                />
            </Modal>
        </Spin>
    );
};

export default EditOrderForm;
